import { graphql, Link } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import ContactForm from "../components/contactForm/form";
import Features from "../components/features/features";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import ImageText from "../components/imageText/imageText";
import Seo from "../components/seo";
import SlickArrowLeft from "../components/SlickArrowLeft";
import SlickArrowRight from "../components/slickArrowRight";
import Testimonials from "../components/testimonials/testimonials";
import Title from "../components/title/title";
import Top from "../components/top/top";
import gradientBg from "../images/CustomerStoriesCViche/gradient-pink-bg.png";
import iconAdv1 from "../images/CustomerStoriesCViche/icon-adv-1.png";
import iconAdv2 from "../images/CustomerStoriesCViche/icon-adv-2.png";
import iconAdv3 from "../images/CustomerStoriesCViche/icon-adv-3.png";
import YoutubeVideo from './../components/youtubeVideo/youtubeVideo';

// markup
const CustomerStoriesCVichePage = ({ location, data }) => {
  const formRef = React.useRef(null);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const topProps = {
    layout: 2,
    title: <>At Orders.co we treat our customers as our partners.</>,
    hideLine: true,
    subtitle: ``,
    scrollToForm: scrollToForm,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="customers"
        src="../images/CustomerStoriesCViche/customers.png"
        className="mw-100"
      />
    ),
  };

  const testimonials = [
    {
      image: (
        <StaticImage
          placeholder="none"
          loading="eager"
          src="../images/CustomerStoriesCViche/Jessica.png"
          alt="Jessica Morales"
          className="mw-100 w-100"
        />
      ),
      text: `The team is just amazing, whenever we have a question just one call and they fix the issue.
                I’ve been very happy since I started using orders.co, because my job has become a lot easier.`,
      author: "Jessica Morales",
      position: "Owner of C-viche Express Mexican Restaurant",
    },
  ];

  const testimonialsProps = {
    layout: 5,
    items: testimonials,
    title: (
      <span className="text-left d-block">
        C-viche Express Mexican Restaurant
      </span>
    ),
    tag: "",
  };

  const features = [
    {
      bg: "#DEFFEE",
      icon: iconAdv1,
      title: "Personalized Website",
      description: `Create your very own ordering website in no time with just your menu.`,
    },
    {
      bg: "#F1F7FF",
      icon: iconAdv2,
      title: "Direct Ordering",
      description: `Cut out the middlemen and give your customers an easy way to order directly from you without the commission!`,
    },
    {
      bg: "#FFF7E3",
      icon: iconAdv3,
      title: "Reliable Delivery",
      description: `Offer delivery on all website orders with in-house staff or through our delivery partners.`,
    },
  ];

  const imageTextProps1 = {
    layout: "equal-big",
    title: "Customers Situation",
    titleStyle: "",
    imagePosition: "left",
    description: (
      <p style={{ fontSize: "22px" }}>
        Jessica Morales is the owner and operator of C~Viche Express, a quaint
        mexican seafood restaurant in the San Fernando Valley. Jessica was
        initially hesitant to use delivery apps, but through the onset of the
        pandemic she had no other choice. While the additional revenue was
        welcomed, Jessica soon realized that online ordering brough with it a
        new set of problems.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="delivery"
        src="../images/CustomerStoriesCViche/tablet-delivery.png"
      />
    ),
  };

  const imageTextProps2 = {
    layout: "equal-big",
    title: "The Challenge",
    titleStyle: "",
    imagePosition: "right",
    description: (
      <p style={{ fontSize: "22px" }}>
        As orders came rolling in, C~Viche’s staff quickly became overwhelmed.
        Few of them had experience with delivery apps and were confused by the
        different user interfaces. This began to cause issues with order
        fulfillment and menu updates. Being a restaurant that is primarily known
        for fresh seafood, these were two major issues.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="challenge"
        src="../images/CustomerStoriesCViche/challenge.png"
      />
    ),
  };

  const imageTextProps3 = {
    layout: "equal-big",
    title: "Orders.co Solution",
    titleStyle: "",
    imagePosition: "left",
    description: (
      <p style={{ fontSize: "22px" }}>
        Soon after, Jessica found Orders.co and signed up for the Premium
        Package. Consolidating all her orders into one easy to manage platform.
        She also worked closely with a Menu Specialist to carefully craft her
        perfect master menu. Which was turned into a custom commission-free
        ordering website to increase revenue.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="orders.co solution"
        src="../images/CustomerStoriesCViche/Orders.co-Solution.png"
      />
    ),
  };

  const imageTextProps4 = {
    layout: "equal-big",
    title: "The Outcome",
    titleStyle: "",
    imagePosition: "right",
    description: (
      <p style={{ fontSize: "22px" }}>
        Since partnering with Orders.co, the C~Viche Express staff has
        completely eliminated all fulfillment issues and love how easily they
        can update item availability through their Master Menu. Total orders per
        month have doubled with a 40% increase in delivery app revenue. Her
        ordering website is gaining popularity and allows Jessica to offer her
        customers delivery through Orders.co’s truster fulfillment partners.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="outcome"
        src="../images/CustomerStoriesCViche/outcome.png"
      />
    ),
  };

  const features2 = [
    {
      bg: "#ff6b0033",
      number: 1,
      title: "",
      description: `For the quaint mexican seafood restaurant this is a major issue as the majority of their menu is perishable. This means orders needed to be expedited quickly if the customer is to receive the freshest product possible.`,
    },
    {
      bg: "#ff6b0033",
      number: 2,
      title: "",
      description: `It was at this time that Jessica came across Orders.co. With a Premium
                Package, C~Viche’s tablets were consolidated into one machine and staff could
                process incoming orders easily in one interface. She also received help from a
                Menu Team Specialist, to rebuild her menu for a personalized commission-free
                ordering website.`,
    },
    {
      bg: "#ff6b0033",
      number: 3,
      title: "",
      description: `Now C~Viche Express is receiving more orders than ever and Jessica never
                has to worry about a disappointed customer. The improved efficiency and promotion
                of her commission-free ordering site has increased her revenue by
                216% in just 5 months!`,
    },
  ];

  const sliderSettings = {
    dots: false,
    arrows: false,
    slidesToShow: 2,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 767.9,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Seo
        title={"Customer Stories C-Viche"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <Top {...topProps} />
        <Testimonials {...testimonialsProps} />
        <Features title="Top Benefits" items={features} />
        <div
          className="bg-desktop pb-0 pb-md-5 mb-0"
          style={{
            backgroundImage: `url(${gradientBg})`,
            backgroundSize: `100% 100%`,
          }}
        >
          <YoutubeVideo
            videoId={"hV4bocrJ6oc"}
            image={<StaticImage
              placeholder="none"
              loading="eager"
              alt="youtube-placeholder"
              className="mw-100 w-100 mh-100"
              src="../images/CustomerStoriesCViche/Jessica Morales.png"
            />}
          ></YoutubeVideo>
          <Title>
            <b
              className="mt-5 mt-md-0"
              style={{ fontSize: "42.861px", lineHeight: "64px" }}
            >
              All Your Online Orders in One Device
            </b>
          </Title>
          <ImageText {...imageTextProps1} />
          <ImageText {...imageTextProps2} />
          <ImageText {...imageTextProps3} />
          <ImageText {...imageTextProps4} />
        </div>
        <div
          className="d-none d-xl-block"
          style={{ marginBottom: "183px" }}
        ></div>
        <Features
          title={
            <span className="text-center d-block">
              C~Viche express was struggling maintaining 3 separate tablets with
              limited counterspace.
            </span>
          }
          items={features2}
        />
        <Title>
          <b
            className="text-center text-lg-left d-block"
            style={{ fontSize: "42.861px", lineHeight: "64px" }}
          >
            From Local Diners to Multinational Chains
          </b>
        </Title>
        <div className="container mb-3 mb-lg-5 pb-3 pb-lg-5">
          <Slider {...sliderSettings}>
            <div className="col-12">
              <div className="post-item d-flex flex-column">
                <div className="d-flex justify-content-center">
                  <StaticImage
                    placeholder="blurred"
                    alt="Independent Restaurants"
                    src="../images/CustomerStoriesCViche/slider-img-1.png"
                    className="mw-100"
                  />
                </div>

                <Link to="/independent-restaurants/" className="post-title">
                  Independent Restaurants
                </Link>
                <div className="post-description">
                  Orders.co was built from the ground up to support restaurants
                  of varying sizes. Our tools help cut costs.
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="post-item d-flex flex-column">
                <div className="d-flex justify-content-center">
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="Chain Restaurants"
                    src="../images/CustomerStoriesCViche/slider-img-2.png"
                    className="mw-100"
                  />
                </div>
                <Link to="/chain-restaurants/" className="post-title">
                  Chain Restaurants
                </Link>
                <div className="post-description">
                  The Orders.co suite was built to facilitate chain restaurants'
                  needs like never before.
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <div ref={formRef}>
          <ContactForm></ContactForm>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "customer-stories-cviche" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
  }
`;

export default CustomerStoriesCVichePage;
